<template>
  <div class="enterprise">
    <div class="title-box">
      <img class="ent-title" :src="require('../../../assets/image/index/specialist.png')" />
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list">
        <div class="item-head">
          <div class="head-fs">{{item.logoname}}</div>
        </div>
        <div class="content-box">
          <div class="name-fs">{{item.name}}</div>
          <div class="industry-fs">{{item.industry}}</div>
        </div>
      </div>
      <div class="er-more" style="margin: 45px auto 0;" @click="goentErpriseMore">更多企业></div>
    </div>
  </div>
</template>

<script>
  import datalist from "@/static/erpriselist.json";
export default {
  name: "enterpriseList",
  props: {
    data: Object
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectExpertId = 0;

        }
      }
    },
  },
  data() {
    return {
      selectExpertId: -1,
      list: [
        {name: "银川通达公路工程试验检测有限公司", industry:"专业技术服务业"},
        {name: "宁夏金彤枸杞生物制品有限公司", industry:"农副食品加工业"},
        {name: "宁夏夏盛实业集团有限公司", industry:"其他制造业"},
        {name: "西部安全认证中心有限责任公司", industry:"软件和信息技术服务业"},
        {name: "宁夏亿能环保科技有限公司", industry:"橡胶和塑料制品业"},
        {name: "宁夏康亚药业股份有限公司", industry:"医药制造业"},
      ]
    };
  },
  async mounted() {
    this.list = datalist.slice(0,6)
    this.list = this.list.map(v => {
      v.logoname = v.name.slice(0,10)
      v.scalelist = v.scale.split(',')
      return v
    })
  },
  methods: {
    setExpertLabel(id) {
      this.selectExpertId = id;
    },
    goentErpriseMore() {
      this.$router.push({
        path: "erpriselist",
      });
    },
    goExpertDetail(id) {
      this.$router.push({
        path: "experts",
        query: {
          shopId: id
        }
      });
    }
  }
};
</script>

<style scoped>
.enterprise {
  width: 100%;
}

.ent-title {
  width: 487px;
  height: 45px;
  margin-top: 50px;
  text-align: center;
}
.title-box{
  text-align: center;
}
.list{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 60px;
}
.item{
  display: flex;
  margin-top: 30px;
  width: 380px;
  height: 170px;
  /*background: red;*/
  border-radius: 8px;
  padding: 20px;
}
.item:hover {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}
.item-head{
  width: 130px;
  min-width: 130px;
  height: 130px;
  background: #435886;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.head-fs{
  width: 90px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  line-height: 25px;
}

.name-fs{
  margin-top: 22px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 26px;
}
.industry-fs{
  margin-top: 20px;
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
}
</style>
